import React, { useEffect, useContext, useState, useRef } from "react";
import {
  Button,
  Icon,
  Table,
} from "semantic-ui-react";
import { colors } from "@material-ui/core";
import Toast from "../../generic/Toast";
import { getAcademyStockRegister, getAcademyProductHistory } from "../../../apiclients/ProductApiClient";
import { AppContext } from "../../../AppContext";
import GenericLoader from "../../generic/GenericLoader";
import AddProductToRegister from "../stockRegister/AddProductToRegister";
import { ROLES } from '../../../utils/constants';
import CustomModal from "../../generic/CustomModal";
import RentSaleProducts from "../../dashboard/booked-booking/RentSaleProducts";
import DiscardProduct from "../stockRegister/DiscardProduct";
import AssignToAcademy from "../stockRegister/AssignToAcademy";
import StockHistory from "../stockRegister/StockHistory";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import { getAllAcademySportsOfArena } from "../../../apiclients/AcademyClient";
import AcademyStockHistory from "./AcademyStockHistory";
import AcademyDiscardProduct from "./AcademyDiscardProduct";
import ExportToExcel from "../../generic/ExportToExcel";

// import DeleteProductFromMaster from "./DeleteProductFromMaster";
// import EditProductInMaster from "./EditProductInMaster";

const AcademyStockRegister = (props) => {
  const { history } = props;
  const context = useContext(AppContext);
  const user = context.user;
  const sports = useRef([]);

  const [stockRegister, setStockRegister] = useState([]);
  const [stockHistory, setStockHistory] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [assignToAcademyModal, setAssignToAcademyModal] = useState(false); // to show assign to academy modal
  const [discardModal, setDiscardModal] = useState(false); // to show discard modal
  const [historyModal, setHistoryModal] = useState(false); // to show History modal
  const [selectedSport, setSelectedSport] = useState({});

  const [showRentOrSell, setShowRentOrSell] = useState(false);
  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});

  const loadData = async (sportsData) => {
    setisLoading(true);
    return getAcademyStockRegister(
      context.selectedFacility,
      context.selectedArena.arenaId,
      sportsData.value
    ).then((response) => {
      const data = response.data;
      setStockRegister(data.data);
      setisLoading(false);
    });
  };

  const changeSport = (event, { value }) => {
    const data = sports.current.find((facility) => facility.value === value);
    setSelectedSport(data);
    loadData(data);
  };

  const openHistoryModal = async (product) => {
    setisLoading(true);
    setSelectedProduct(product);
    return getAcademyProductHistory(
      context.selectedFacility,
      context.selectedArena.arenaId,
      selectedSport.value,
      product.id
    ).then((response) => {
      const data = response.data;
      setStockHistory(data);
      setHistoryModal(true);
      setisLoading(false);
    });
  };


  const openDiscardModal = (product) => {
    setSelectedProduct(product);
    setDiscardModal(true);
  };

  useEffect(() => {
    if (reload) {
      loadData(selectedSport).then(() => {
        setReload(false);
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (context.selectedFacility && context.selectedArena) {
      getAllAcademySportsOfArena(context.selectedFacility, context.selectedArena.arenaId).then(
        (response) => {
          if (response.status === 200) {
            const sport = response.data;
            const dropdownSportsData = sport.map(
              (x, index) => ({
                key: x.sportId,
                value: x.sportId,
                text: x.sportName,
              })
            );
            sports.current = dropdownSportsData;
            setSelectedSport(dropdownSportsData[0]);
            loadData(dropdownSportsData[0]);
          } else {
            history.push("/extranet/dashboard");
          }
        }
      ).catch((err) => {
        history.push("/extranet/dashboard");
      });
    }
  }, [context.selectedFacility, context.selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

  const tableHeader = [
    { label: "Product Name", value: "productName" },
   { label: "ERP Stock", value: "productQuantity" },
    { label: "Actual Stock" },
    { label: "Matching ?" },
  ];
  const fileName = "Academy Stock Register" + moment().format('MMMM Do YYYY');
  const sheetName = moment().format('MMMM Do YYYY, h_mm a');
  
  const sheets = [
    {
      name: sheetName,
      columns: tableHeader,
      dataSet: stockRegister,
    },
  ];

  return isLoading ? <GenericLoader /> : (
    <div style={{ padding: "16px" }}>
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>Academy Stock Register </span>
          </div>
          <div style={{ alignItems: "center" }}>

            <span>
            <div style={{ fontSize: "14px" }}>
              <Dropdown
                onChange={changeSport}
                placeholder='Select...'
                openOnFocus
                selection
                value={selectedSport && selectedSport.value}
                options={sports.current}
              />
</div>
</span>
          </div>
            <span>
                  <ExportToExcel disabled={stockRegister.length === 0} sheets={sheets} 
                  fileName={fileName}
                  buttonName="Download Report"
                  />
                </span>
        </div>


      </div>
      {loader ? (
        <GenericLoader />
      ) : (
        <div style={{ paddingTop: "16px" }}>
          <Toast
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            message={messageContent.message}
            messageHeader={messageContent.header}
            color={messageContent.color}
          />
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>S.No</Table.HeaderCell>
                <Table.HeaderCell>Product Name</Table.HeaderCell>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Sub-Category</Table.HeaderCell>
                <Table.HeaderCell>Closing Stock</Table.HeaderCell>
                <Table.HeaderCell>Selling Price</Table.HeaderCell>
                <Table.HeaderCell>Product History</Table.HeaderCell>
                <Table.HeaderCell>Quick Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stockRegister.map((x, index) => {
                return (
                  <Table.Row key={x.id} >
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{x.productName}

                    </Table.Cell>
                    <Table.Cell>{x.companyName}</Table.Cell>
                    <Table.Cell>{x.category}</Table.Cell>
                    <Table.Cell>{x.subCategory}</Table.Cell>
                    <Table.Cell><b> {x.productQuantity} </b>
                    </Table.Cell>
                    <Table.Cell><b>Rs. {x.sellingPrice}/- </b>
                      {x.availableForRent ? <div>Rental Price - <b>Rs. {x.rentalPrice}/-</b></div> : null}
                    </Table.Cell>
                    <Table.Cell>

                      <Button
                        style={{
                          fontSize: "12px",
                          marginBottom: "8px",
                          backgroundColor: colors.orange["800"],
                          color: "#fff",
                        }}
                        onClick={() => openHistoryModal(x)}
                      >
                        Show History
                      </Button>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                        }}
                        disabled={user.role === ROLES.ADMIN || user.role === ROLES.ACCOUNTANT || user.role === ROLES.REGIONAL_MANAGER ? false : true}
                        onClick={() => openDiscardModal(x)}
                      >
                        Discard Stock
                      </Button>
                      { moment.duration(moment(new Date(x.lastUpdated.seconds * 1000)).diff(moment())).asHours() > -24
                          ? <div
                            style={{
                              backgroundColor: colors.teal["500"],
                              borderRadius: "12px",
                              color: "#fff",
                              textAlign: "center",
                              fontWeight: "500",
                              fontSize: "10px",
                              padding: "2px",
                              margin: "16px 0px 5px 0px"
                            }}
                          >Updated in Last 24 hours</div>
                          : ""}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <br />

How to Manage Academy Stock Register?
<Table celled padded>
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Stock Module Overview</Table.HeaderCell>
      <Table.HeaderCell>Managing Academy Stock Register</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
  <Table.Body>
    <Table.Row key={0}>
      <Table.Cell><div className="player2">
        <iframe width="200" height="100" src="https://www.youtube.com/embed/aHEhpG25AJ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
      </div></Table.Cell>
      <Table.Cell><div className="player2">
        <iframe width="200" height="100" src="https://www.youtube.com/embed/GyaMI0qCjP4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
      </div></Table.Cell>
    </Table.Row>
  </Table.Body>

</Table>
        </div>

      )}
      {discardModal ? (
        <AcademyDiscardProduct
          showModal={setDiscardModal}
          setReload={setReload}
          setMessage={setMessageContent}
          isLoading={setisLoading}
          product={selectedProduct}
          selectedSport={selectedSport}
        />
      ) : null}


      {historyModal ? (
        <AcademyStockHistory
          showModal={setHistoryModal}
          stockData={stockHistory}
          selectedProduct={selectedProduct}
        />
      ) : null}

    </div>
  );
};

export default AcademyStockRegister;
